import { PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import BasicScreen from "../components/BasicScreen";
import SmallText from "../components/SmallText";
import Text from "../components/Text";

const ProjectsPage = (props: PageProps) => {
  return (
    <BasicScreen {...props}>
      <div className="hidden md:block absolute top-22 left-1/2 w-1/4 h-28 bg-blue -z-10" />
      <div className="flex w-full p-4 flex-col md:mt-22 ">
        <div className="bg-blue p-4  w-full flex flex-col ">
          <span className="mb-2 text-2xl">Projekti</span>
          <div className="w-full flex flex-col items-center">
            <a href="https://gutenbergovpark.com" target="_blank" rel="noopener noreferrer" className="flex flex-col items-center hover:border-yellow-400 hover:border p-2">
              <StaticImage
                src="../images/gutenbergov.png"
                alt="Gutenbergov Park"
                className="h-20 w-20 object-contain self-center"
              />
              <Text class="mt-2">"Gutenbergov Park"</Text>
              <SmallText>Višeporodični stambeno/poslovni kompleks P+3</SmallText>
            </a>
          </div>
        </div>
      </div>
    </BasicScreen>
  );
};

export default ProjectsPage;
